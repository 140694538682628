<template>
	<div>
		<Modal
			class="doc-preview-modal"
			:value="visible"
			:mask-closable="false"
			:scrollable="true"
			:fullscreen="fullscreen"
			:footer-hide="true"
			@on-cancel="handleCancel"
			:width="1200"
			:styles="{ top: fullscreen ? 0 : '20px' }"
		>
			<div slot="header" class="modal-header">
				<h2>文件预览</h2>
				<a
					href="javascript:;"
					class="fullscreen"
					@click="fullscreen = !fullscreen"
				>
					<Tooltip :content="fullscreen ? '退出全屏' : '全屏查看'">
						<i v-if="!fullscreen" class="iconfont icon-fullscreen-fill"></i>
						<i v-else class="iconfont icon-fullscreen-exit-fill"></i>
					</Tooltip>
				</a>
			</div>
			<div v-if="row">
				<Button
					size="large"
					type="primary"
					shape="circle"
					style="position: absolute; bottom: 20%; right: 10%; z-index: 999"
					@click="handleAnnotation"
					>批注</Button
				>
			</div>
			<div
				style="position: relative"
				:style="{ height: gridHeight - 15 + 'px', width: gridWidth + 'px' }"
			>
				<!--pdf.js预览-->
				<iframe :src="src" style="width: 100%; height: 100%"></iframe>
			</div>

			<div slot="footer">
				<!--<Button style="margin-right: 8px"-->
				<!--@click="handleElecSign">电子签名</Button>-->
				<!--<Button style="margin-right: 8px"-->
				<!--@click="handleCancel">关闭</Button>-->
			</div>
		</Modal>
		<Annotation
			:visible.sync="annotationVisible"
			:taskId="taskId"
			entry="documentPreview"
			:procInstId="procInstId"
			@onOk="onOk"
			@onCancel="annotationVisible = false"
			:annotationObj="annotationObj"
		></Annotation>
	</div>
</template>

<script>
import { mapState } from "vuex"
import Annotation from "@/views/Workbench/components/Annotation.vue"

export default {
	name: "DocumentPreview",
	components: {
		Annotation
	},
	props: {
		documentSrc: {
			type: String,
			default: ""
		},
		visible: {
			type: Boolean,
			default: false
		},
		isFullScreen: {
			type: Boolean,
			default: false
		},
		id: {
			type: String,
			default: "documentPreview"
		},
		row: {
			type: Object,
			default: () => {}
		},
		taskId: {
			type: String,
			default: ""
		},
		procInstId: {
			type: String,
			default: ""
		}
	},
	data() {
		return {
			fullscreen: false,
			annotationVisible: false,
			annotationObj: {},
			listData: [],
			src: ""
		}
	},
	computed: {
		...mapState({
			dataGridHeight: state => state.contentHeight - 170
		}),
		gridHeight() {
			return this.fullscreen
				? document.body.clientHeight - 71
				: this.dataGridHeight + 117
		},
		gridWidth() {
			return this.fullscreen ? document.body.clientWidth - 33 : 1170
		}
	},
	watch: {
		visible() {
			if (this.visible) {
				this.$nextTick(() => {
					if (this.documentSrc && this.id) {
						// pdf.js预览
						const filePath = encodeURIComponent(this.documentSrc)
						this.src = `${process.env.VUE_APP_PUBLIC_BASE_URL}resource/pdf/web/viewer.html?file=${filePath}`
					}
				})
				this.fullscreen = !!this.isFullScreen
			}
		}
	},
	methods: {
		// 进行批注
		handleAnnotation() {
			this.listData = []
			this.$parent.listData.forEach(item =>
				item.list.forEach(val => {
					this.listData.push(val)
				})
			)
			this.annotationObj = {
				...this.listData.filter(item => item.id === this.row.id)[0],
				annotation: ""
			}
			this.annotationVisible = true
		},
		// 批注完成
		onOk() {
			this.annotationVisible = false
			this.$parent.onOk()
			// this.$parent.getProcessDocList();
		},
		handleCancel() {
			this.fullscreen = false
			this.annotationVisible = false
			this.$emit("onCancel")
		},
		// 电子签名
		handleElecSign() {}
	}
}
</script>

<style lang="less">
.doc-preview-modal {
	.modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
	.document-preview-shadow {
		position: absolute;
		/*width: calc(100% - 15px);*/
		top: 40px;
		left: 0;
		right: 15px;
		bottom: 15px;
	}
}
</style>
