<template>
	<Modal
		draggable
		sticky
		reset-drag-position
		class="custom-add-modal-header"
		:mask="false"
		:value="visible"
		:mask-closable="false"
		:closable="true"
		@on-cancel="onCancel"
		:styles="{
			top: fullscreen ? 0 : '100px',
			marginRight: entry === 'documentPreview' ? 0 : 'auto'
		}"
		width="460"
	>
		<div slot="header" class="add-modal-header">
			<h2>{{ title }}<span style="font-size: 12px">（弹框可拖拽）</span></h2>
		</div>
		<Form :model="formData" ref="form" label-position="top" :rules="rules">
			<FormItem
				label=""
				v-if="visible"
				style="margin-bottom: 0"
				prop="annotation"
			>
				<Input
					v-model="formData.annotation"
					type="textarea"
					:rows="3"
					placeholder="请输入批注"
				>
				</Input>
				<!--:autosize="{minRows: 3,maxRows:3}"-->
			</FormItem>
		</Form>

		<div slot="footer">
			<Button style="margin-right: 8px" @click="onCancel">取消</Button>
			<Button type="primary" @click="onOk" :loading="loading">确定</Button>
		</div>
	</Modal>
</template>

<script>
import api from "@/api/workbench/workbench"

const { addAnnotation, updateAnnotation } = api
export default {
	name: "Annotation",
	props: {
		visible: {
			type: Boolean,
			default: false
		},
		annotationObj: {
			type: Object,
			default: () => {}
		},
		entry: {},
		procInstId: {},
		taskId: {}
	},
	data() {
		return {
			loading: false,
			formData: {},
			title: "批注",
			rules: {},
			fullscreen: false
		}
	},
	computed: {
		gridHeight() {
			return this.fullscreen
				? (document.body.clientHeight - 80) / 30
				: (document.body.clientHeight - 100) / 30
		}
	},
	watch: {
		visible(val) {
			if (!val) {
				this.$refs.form.resetFields()
				return
			}
			this.formData.annotation = this.annotationObj.annotation
		}
	},
	methods: {
		onCancel() {
			this.$emit("onCancel")
		},
		async onOk() {
			const valid = await this.$refs.form.validate()
			if (valid) {
				this.loading = true
				let res = false
				if (this.entry === "edit") {
					res = await updateAnnotation({
						projectId: this.annotationObj.projectId,
						taskId: this.taskId,
						id: this.annotationObj.id,
						annotation: this.formData.annotation
					})
				} else {
					res = await addAnnotation({
						projectId: this.annotationObj.projectId,
						procInstId: this.procInstId,
						taskId: this.taskId,
						docfileId: this.annotationObj.id,
						docfileName: this.annotationObj.name,
						version: this.annotationObj.version,
						annotation: this.formData.annotation
					})
				}

				if (res) {
					this.$Message.success("操作成功！")
					this.$emit("onOk", {
						...this.annotationObj,
						annotation: this.formData.annotation
					})
				}
				this.loading = false
			}
		},
		handleFullScreen() {
			// 全屏查看
			this.fullscreen = !this.fullscreen
		}
	}
}
</script>

<style lang="less">
.custom-add-modal-header {
	.annotation-textarea {
		width: 100%;
		min-height: 100px;
		padding: 10px;
	}
	.add-modal-header {
		overflow: hidden;
		h2 {
			float: left;
		}
		.fullscreen {
			float: right;
			margin-right: 40px;
			text-decoration: none;
		}
		.iconfont {
			font-size: 20px;
		}
	}
}
</style>
